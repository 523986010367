import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { TC } from "../styles/color";

const ColLeft = styled(Col)`
  color: #fff;
  padding: 10px;
  line-height: 1.4em;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 900px) {
    padding: 0;
  }

  b {
    display: block;
    margin-top: 14px;
    font-size: 22px;
    font-weight: normal;
  }

  img {
    margin-left: 16px;
    height: 50px;
  }
`;

const ColRight = styled(Col)`
  background-color: #fff;
  padding: 41px 25px;
  h3 {
    color: rgba(0, 0, 0, 0.65);
    font-size: 1.6em;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
  background-color: #627184;
  &.row--2 {
    background-color: #a6a1a1;
  }

  .mobile-hidden {
    @media (max-width: 900px) {
      display: none;
    }
  }
`;

const IconDesc = styled.p`
  float: right;
  font-size: 0.6em;
  color: #ccc;
`;

function SummaryDesc({ getStatusIndex, avgTemp, avgHumid, avgCo2, avgVoc, avgPm25 }) {
  const textAry = ["매우 나쁨", "나쁨", "보통", "좋음", "매우 좋음"];

  return (
    <>
      <StyledRow justify="space-around" align="middle">
        <ColLeft span={6}>
          <Row justify="space-around" align="middle">
            <Col span={12} className="mobile-hidden">
              <img src="/cloud.png" alt="co2" />
            </Col>
            <Col span={12}>
              이산화탄소(ppm)
              <b>{avgCo2}</b>
            </Col>
          </Row>
        </ColLeft>
        <ColRight span={18}>
          <h3>
            현재 이산화탄소 수치는 {avgCo2}ppm으로
            <br />
            <span style={{ color: TC[getStatusIndex("co2", avgCo2)] || "#999" }}>{textAry[getStatusIndex("co2", avgCo2)] || "미집계"}</span> 상태 입니다.
          </h3>
        </ColRight>
      </StyledRow>

      <StyledRow justify="space-around" align="middle">
        <ColLeft span={6}>
          <Row justify="space-around" align="middle">
            <Col span={12} className="mobile-hidden">
              <img src="/virus.png" alt="voc" />
            </Col>
            <Col span={12}>
              휘발성
              <br />
              유기화합물(ppb) <b>{avgVoc}</b>
            </Col>
          </Row>
        </ColLeft>
        <ColRight span={18}>
          <h3>
            현재 휘발성유기화합물 수치는 {avgVoc}ppb로
            <br />
            <span style={{ color: TC[getStatusIndex("voc", avgVoc)] || "#999" }}>{textAry[getStatusIndex("voc", avgVoc)] || "미집계"}</span> 상태 입니다.
          </h3>
        </ColRight>
      </StyledRow>

      <StyledRow justify="space-around" align="middle">
        <ColLeft span={6}>
          <Row justify="space-around" align="middle">
            <Col span={12} className="mobile-hidden">
              <img src="/sand.png" alt="pm25" />
            </Col>
            <Col span={12}>
              미세먼지(㎍/m³) <b>{avgPm25}</b>
            </Col>
          </Row>
        </ColLeft>
        <ColRight span={18}>
          <h3>
            현재 미세먼지 수치는 {avgPm25}㎍/m³로
            <br />
            <span style={{ color: TC[getStatusIndex("pm25", avgPm25)] || "#999" }}>{textAry[getStatusIndex("pm25", avgPm25)] || "미집계"}</span> 상태 입니다.
          </h3>
        </ColRight>
      </StyledRow>

      <StyledRow justify="space-around" align="middle" className="row--2">
        <ColLeft span={6}>
          <Row justify="space-around" align="middle">
            <Col span={12} className="mobile-hidden">
              <img src="/thermometer.png" alt="temp" />
            </Col>
            <Col span={12}>
              온도(℃)<b>{avgTemp}</b>
            </Col>
          </Row>
        </ColLeft>
        <ColRight span={18}>
          <h3>
            현재 온도 수치는 {avgTemp}℃로
            <br />
            <span style={{ color: TC[getStatusIndex("temp", avgTemp)] || "#999" }}>{textAry[getStatusIndex("temp", avgTemp)] || "미집계"}</span> 상태 입니다.
          </h3>
        </ColRight>
      </StyledRow>

      <StyledRow justify="space-around" align="middle" className="row--2">
        <ColLeft span={6}>
          <Row justify="space-around" align="middle">
            <Col span={12} className="mobile-hidden">
              <img src="/humidity.png" alt="humid" />
            </Col>
            <Col span={12}>
              습도(%) <b>{avgHumid}</b>
            </Col>
          </Row>
        </ColLeft>
        <ColRight span={18}>
          <h3>
            현재 습도 수치는 {avgHumid}%로
            <br />
            <span style={{ color: TC[getStatusIndex("humid", avgHumid)] || "#999" }}>{textAry[getStatusIndex("humid", avgHumid)] || "미집계"}</span> 상태
            입니다.
          </h3>
        </ColRight>
      </StyledRow>
      <IconDesc>Icon made by Freepik</IconDesc>
    </>
  );
}

export default SummaryDesc;
