import React from "react";
import styled from "styled-components";
import Header from "Components/Header";
import { Layout } from "antd";

const StyledLayout = styled(Layout)`
  overflow:hidden;
`;

const Container = styled.div`
  background: #e8e8e8;
  padding: 38px 18px;
  width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

function DefaultLayout({ children }) {
  return (
    <StyledLayout>
      <Container>
        <Header />
        <Layout.Content>{children}</Layout.Content>
      </Container>
    </StyledLayout>
  );
}
export default DefaultLayout;
