import React, { useEffect } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout";
import { Form, Input, Button, message } from "antd";
import { useHistory, Link } from "react-router-dom";
import { MailOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import { Wrapper } from "../../styles/common";
import { SIGNIN } from "../../graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

function Login() {
  const history = useHistory();

  const [signin] = useMutation(SIGNIN, {
    onCompleted(data) {
      localStorage.setItem("currentUser", data.signin.accessToken);
      history.push("/");
    },
    onError(error) {
      console.error(error);
      message.error("로그인에 실패하였습니다.");
    },
  });

  const handleFinish = async (values) => {
    if (localStorage.getItem("currentUser")) localStorage.removeItem("currentUser");
    await signin({ variables: { id: values.email, password: values.password } });
  };

  return (
    <DefaultLayout>
      <Wrapper>
        <h2>
          <LoginOutlined /> 로그인
        </h2>

        <Form
          {...layout}
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={handleFinish}
        >
          <Form.Item
            label="이메일"
            name="email"
            rules={[
              {
                required: true,
                message: "가입한 이메일 주소를 입력해 주세요!",
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="비밀번호"
            name="password"
            rules={[
              {
                required: true,
                message: "비밀번호를 입력해 주세요!",
              },
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" className="login-form-button">
              로그인
            </Button>
            <Link to={`/findpassword`} style={{ marginLeft: "20px" }}>
              비밀번호 찾기
            </Link>
          </Form.Item>
        </Form>
      </Wrapper>
    </DefaultLayout>
  );
}

export default Login;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};
