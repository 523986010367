import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "Pages/Dashboard";
import InbodyDashboard from "Pages/inbody/Dashboard";
import AppDashboard from "Pages/AppDashboard";
import Login from "Pages/Auth/Login";
import FindPassword from "Pages/Auth/FindPassword";
import ModifyPassword from "Pages/Auth/ModifyPassword";
import PrivateRoute from "./PrivateRoute";
import AppChatbot from "Pages/AppChatbot";
import AiSetting from "Pages/AiSetting";

function Routes() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/findpassword" component={FindPassword} />
      <Route path="/appdashboard/user/:userId/token/:token" component={AppDashboard} />
      <Route path="/appdashboard/user/:userId/erv/:ervId/token/:token" component={AppDashboard} />
      <Route path="/app/chatbot/user/:userId/token/:token" component={AppChatbot} />
      <PrivateRoute path="/modifypassword" component={ModifyPassword} />
      <PrivateRoute path="/mode/paging" component={InbodyDashboard} />
      <PrivateRoute path="/setting" component={AiSetting} />
      <PrivateRoute path="/" component={Dashboard} />
    </Switch>
  );
}

export default Routes;
