import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

const StyledLayout = styled(Layout)`
  overflow: hidden;
`;

const Container = styled.div`
  background: #e8e8e8;
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

function InbodyLayout({ children }) {
  return (
    <StyledLayout>
      <Container>
        <Layout.Content>{children}</Layout.Content>
      </Container>
    </StyledLayout>
  );
}
export default InbodyLayout;
