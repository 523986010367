import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import SelfContext from "./selfContext";
import { ME } from "./graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

const AUTH_STATE_LOADING = `loading`;
const AUTH_STATE_OK = `ok`;
const AUTH_STATE_FAIL = `fail`;

function PrivateRoute({ component: Component, children, ...rest }) {
  const [authState, setAuthState] = useState(AUTH_STATE_LOADING);
  const [self, setSelf] = useState();

  const [getMe] = useLazyQuery(ME, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setSelf(data.me);
      setAuthState(AUTH_STATE_OK);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    (async () => {
      const browserToken = localStorage.getItem("currentUser");
      if (browserToken) {
        getMe();
      } else {
        setAuthState(AUTH_STATE_FAIL);
      }
    })();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authState === AUTH_STATE_LOADING) return <></>;
        else if (authState === AUTH_STATE_OK) {
          if (Component)
            return (
              <SelfContext.Provider value={{ self }}>
                <Component {...props} />
              </SelfContext.Provider>
            );
          else if (children) return <SelfContext.Provider value={{ self }}>{children}</SelfContext.Provider>;
        } else if (authState === AUTH_STATE_FAIL) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
