import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";
import defaultChartOps from "./config";
import styled from "styled-components";

Boost(Highcharts);

const Container = styled.div`
  margin-bottom: 20px;
  border-radius: 20px;
  background: white;
  padding: 30px 0px 20px 0px;
  ${(props) => props.isApp && "padding-top: 0px;"}
`;

class Charts extends Component {
  state = {
    options: {
      ...defaultChartOps.common,
    },
    longListCondition: null,
  };

  componentDidMount() {
    let { kind, isVsp } = this.props;
    let title;

    let options = defaultChartOps[kind];

    if (kind === "fanSpeed") {
      title = "Fand Speed";
    } else if (kind === "awairScore") {
      title = "Awair Score";
    } else {
      title = kind[0].toUpperCase() + kind.slice(1);
    }
    options.title.text = title;

    let yAxisIndexForHide;
    isVsp === "TRUE" ? (yAxisIndexForHide = 1) : (yAxisIndexForHide = 2);
    options.yAxis[yAxisIndexForHide] = {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    };

    this.setState({
      options: {
        ...this.state.options,
        ...options,
        series: [...this.props.data],
      },
      // longListCondition: longListCondition,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        options: { ...this.state.options, series: [...this.props.data] },
      });
    }
  }

  render() {
    return (
      <>
        {this.state.options.series !== undefined && (
          <Container isApp={this.props.isApp}>
            <HighchartsReact highcharts={Highcharts} options={this.state.options} updateArgs={[true]} />
          </Container>
        )}
      </>
    );
  }
}

export default Charts;
