import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "../Layouts/DefaultLayout";
import moment from "moment";
import { DatePicker, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import SelfContext from "../selfContext";
import { Wrapper } from "../styles/common";
import Modes from "../Containers/Modes";
import ChartGroup from "../Containers/ChartGroup";
import { INIT_DASHBOARD_PAGE } from "../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";
import useInterval from "../hooks/useInterval";

function Dashboard() {
  const { self } = useContext(SelfContext);
  const [date, setDate] = useState(Date.now());
  const [ervs, setErvs] = useState([]);
  const { modeName } = useParams();

  const userId = self.user._id;

  const [initDashboardPage, { loading, error, data, refetch }] = useLazyQuery(INIT_DASHBOARD_PAGE, {
    // onCompleted(data) {
    //   console.log(data);
    //   const ervsArr = [];
    //   data.user.floors.map((floor) => {
    //     floor.ervs.map((erv) => {
    //       ervsArr.push(erv);
    //     });
    //   });
    //   if (ervsArr.length) {
    //     setErvs(ervsArr);
    //     // setCurrentErvId(ervsOptArr[0].value);
    //   }
    // },
  });

  useEffect(() => {
    const startTime = moment(date).startOf("day");
    const endTime = moment(date).endOf("day");
    initDashboardPage({ variables: { userId: userId, from: startTime.valueOf(), to: endTime.valueOf() } });
  }, [date]);

  //  3분 간격으로 챠트 갱신
  useInterval(async () => {
    if (moment(date).isSame(moment(), "day")) {
      refetch();
    }
  }, 1000 * 60 * 3);

  function handleChangeDate(date) {
    setDate(date);
  }

  function disabledDate(current) {
    return current < moment(data.user.createdAt).startOf("dat") || current > moment().endOf("day"); // user 생성 기준
  }

  // TODO: 로딩 이미지

  if (!data) return <DefaultLayout></DefaultLayout>;

  return (
    <DefaultLayout>
      <Wrapper>
        <Row>
          <Col sm={16} xs={24} style={{ marginBottom: "10px" }}>
            <h2>
              <UserOutlined /> {data.user.name}
            </h2>
            <StyledDatePicker onChange={handleChangeDate} defaultValue={moment(date)} format={"YYYY-MM-DD"} disabledDate={disabledDate} inputReadOnly={true} />
          </Col>

          <Col sm={8} xs={24}>
            <Modes userId={userId} />
          </Col>

          {/* {!!ervs.length && (
            <Col sm={8} xs={24}>
              <Modes ervs={ervs} />
            </Col>
          )} */}

          {/* <Select defaultValue={currnetErvId} options={ervsOpt}  */}

          {/* <Col sm={8} xs={24}>
              {erv && erv.status === "active" && <Modes erv={erv} />}
            </Col> */}
        </Row>
      </Wrapper>

      <ChartGroup floors={data.user.floors} date={date} />
    </DefaultLayout>
  );
}

export default Dashboard;

const StyledDatePicker = styled(DatePicker)`
  @media (max-width: 575px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
