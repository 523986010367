import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import SummaryDesc from "../Components/SummaryDesc";
import Summary from "../Components/Summary";

function DailyReport({ date, isPaging, getStatusIndex, avgScore, avgTemp, avgHumid, avgCo2, avgVoc, avgPm25 }) {
  if (isPaging) {
    return (
      <Row gutter={24}>
        <Col span={12}>
          <Summary
            getStatusIndex={getStatusIndex}
            avgScore={avgScore}
            avgCo2={avgCo2}
            avgVoc={avgVoc}
            avgPm25={avgPm25}
            avgTemp={avgTemp}
            avgHumid={avgHumid}
            date={date}
            isPaging={isPaging}
          />
        </Col>
        <Col span={12}>
          <SummaryDesc getStatusIndex={getStatusIndex} avgTemp={avgTemp} avgHumid={avgHumid} avgCo2={avgCo2} avgVoc={avgVoc} avgPm25={avgPm25} />
        </Col>
      </Row>
    );
  } else {
    return (
      <Summary
        getStatusIndex={getStatusIndex}
        avgScore={avgScore}
        avgCo2={avgCo2}
        avgVoc={avgVoc}
        avgPm25={avgPm25}
        avgTemp={avgTemp}
        avgHumid={avgHumid}
        date={date}
      />
    );
  }
}

export default DailyReport;
