import React, { useEffect, useState, useRef } from "react";
import { Spin, Row, Col } from "antd";
import styled from "styled-components";
import InbodyCharts from "../../Components/inbody/Charts";
import moment from "moment";
import useInterval from "../../hooks/useInterval";
import DailyReport from "../DailyReport";
import { TC } from "../../styles/color";

const PageWrap = styled.div`
  padding: 20px 50px;
`;

const Logo = styled.div`
  text-align: right;
  img {
    width: 220px;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 36px;
  }
`;

const AwairMap = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-image: url("/map.jpg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
`;

const ChartsWrap = styled.div`
  position: relative;
`;

const ChartWrap = styled.div`
  position: relative;
`;

const StyledSpin = styled(Spin)`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 32px;
  color: #5b7784;
  font-weight: 300;
  position: absolute;
  top: 16px;
  left: 31px;
  z-index: 1;
`;

const ChartTitle = ({ text, isApp }) => {
  return <SubTitle>{text}</SubTitle>;
};

const criteriaGoodBad = {
  temp: {
    range: [-Infinity, 9, 11, 17, 18, 25, 26, 32, 34, Infinity],
    quality: [0, 1, 2, 3, 4, 3, 2, 1, 0],
  },
  humid: {
    range: [0, 15, 20, 35, 40, 50, 60, 65, 80, Infinity],
    quality: [0, 1, 2, 3, 4, 3, 2, 1, 0],
  },
  co2: {
    range: [0, 600, 1000, 1500, 2500, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  voc: {
    range: [0, 333, 1000, 3333, 8332, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  pm25: {
    range: [0, 15, 35, 55, 75, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  awairScore: {
    range: [0, 70, 80, 90, 95, Infinity],
    quality: [0, 1, 2, 3, 4],
  },
};

const Desc = styled.div`
  position: absolute;
  top: 26px;
  left: 50px;
  z-index: 10;
  font-size: 1.8em;
`;

// function InbodyChartGroup({ awairs, ervs, date, isApp = false, currentPage = 1, setCurrentPage }) {
function InbodyChartGroup({ floors, date, isApp = false, currentPage = 1, setCurrentPage }) {
  const [graphData, setGraphData] = useState(null);
  const [aqi, setAqi] = useState(null);
  const isLoading = useRef("TRUE"); // TRUE | FALSE
  const [isCurrentDate, setIsCurrentDate] = useState(false);
  const [refreshNum, setRefreshNum] = useState(0);
  const [isVsp, setIsVsp] = useState("FALSE"); // READY | TRUE | FALSE

  const [avgScore, setAvgScore] = useState("-");
  const [avgTemp, setAvgTemp] = useState("-");
  const [avgHumid, setAvgHumid] = useState("-");
  const [avgCo2, setAvgCo2] = useState("-");
  const [avgVoc, setAvgVoc] = useState("-");
  const [avgPm25, setAvgPm25] = useState("-");

  const textAry = ["매우 나쁨", "나쁨", "보통", "좋음", "매우 좋음"];

  useEffect(() => {
    (async () => {
      if (!floors) return;

      await initGraphData(floors);
      await initAqiData(floors);
    })();
  }, [floors, refreshNum]);

  async function initAqiData(floors) {
    let awairScore = 0;
    let co2 = 0;
    let humid = 0;
    let pm25 = 0;
    let temp = 0;
    let voc = 0;

    let awairScoreNum = 0;
    let co2Num = 0;
    let humidNum = 0;
    let pm25Num = 0;
    let tempNum = 0;
    let vocNum = 0;

    for (const floor of floors) {
      // 최근 공기질의 평균을 구함
      floor.sections.forEach((section, index) => {
        if (!section.getTodayIndoorAtmLogs || !section.getTodayIndoorAtmLogs.measurements) return true;
        let latestIndex = section.getTodayIndoorAtmLogs.measurements.length - 1;
        const dataArr = section.getTodayIndoorAtmLogs.measurements;
        if (dataArr[latestIndex].awairScore) {
          awairScore += dataArr[latestIndex].awairScore;
          awairScoreNum++;
        }
        if (dataArr[latestIndex].co2) {
          co2 += dataArr[latestIndex].co2;
          co2Num++;
        }
        if (dataArr[latestIndex].humid) {
          humid += dataArr[latestIndex].humid;
          humidNum++;
        }
        if (dataArr[latestIndex].pm25) {
          pm25 += dataArr[latestIndex].pm25;
          pm25Num++;
        }
        if (dataArr[latestIndex].temp) {
          temp += dataArr[latestIndex].temp;
          tempNum++;
        }
        if (dataArr[latestIndex].voc) {
          voc += dataArr[latestIndex].voc;
          vocNum++;
        }
      });
    }

    awairScoreNum && setAvgScore((awairScore / awairScoreNum).toFixed(1));
    co2Num && setAvgCo2((co2 / co2Num).toFixed(1));
    humidNum && setAvgHumid((humid / humidNum).toFixed(1));
    pm25Num && setAvgPm25((pm25 / pm25Num).toFixed(1));
    tempNum && setAvgTemp((temp / tempNum).toFixed(1));
    vocNum && setAvgVoc((voc / vocNum).toFixed(1));
  }

  async function initGraphData(floors) {
    let newGraphData = {
      CO2: [],
      VOC: [],
      awairScore: [],
      fanspeed: [],
    };

    const fanspeedDatas = [];

    const colorArr = ["#00C1C4", "#0BDBA4", "#0AD164", "#0BA1DB", "#0A65D1"];
    const fanspeedColors = ["#FFD215", "#CCA610", "#807030"];

    for (const floor of floors) {
      // 팬스피드 데이터 가공
      floor.ervs.forEach((erv, index) => {
        if (!erv.getTodayErvLogs || !erv.getTodayErvLogs.actions.length) return true;
        const [basic, pro] = formatFanspeedDataForGraph(erv.getTodayErvLogs.isVsp, erv.getTodayErvLogs.actions);
        if (basic.length > 0) {
          fanspeedDatas.push({ name: `fanspeed( ${erv.name} )`, data: basic, color: fanspeedColors[index], ervId: erv._id });
        }
        pro.length > 0 && fanspeedDatas.push({ name: `VSP( ${erv.name} )`, data: pro, yAxis: 1, color: fanspeedColors[index], ervId: erv._id });
      });

      // innerAQI 데이터 가공
      floor.sections.forEach((section, index) => {
        if (!section.getTodayIndoorAtmLogs || !section.getTodayIndoorAtmLogs.measurements) return true;

        let thisName;
        let co2 = [];
        let voc = [];
        let awairScores = [];

        for (const item of section.getTodayIndoorAtmLogs.measurements) {
          // 챠트 데이터
          const ts = moment(item.logtime).valueOf();
          item.co2 && co2.push([ts, item.co2]);
          item.voc && voc.push([ts, item.voc]);
          item.awairScore && awairScores.push([ts, item.awairScore]);

          // 평균 데이터
        }

        thisName = section.name;
        newGraphData.CO2.push({ type: "line", name: thisName, data: co2, color: colorArr[index] });
        newGraphData.VOC.push({ type: "line", name: thisName, data: voc, color: colorArr[index] });
        newGraphData.awairScore.push({ name: thisName, data: awairScores, color: colorArr[index] });
      });

      fanspeedDatas.forEach((fanspeedData, index) => {
        if (!fanspeedData.data) return true;
        newGraphData.fanspeed.push({ type: "area", name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index] });
      });

      setGraphData(newGraphData);
      isLoading.current = "FALSE";
    }
  }

  // 팬스피드 데이터 가공
  const formatFanspeedDataForGraph = (isVsp, rawData) => {
    const basicFanspeedData = [];
    const proFanspeedData = [];
    rawData.forEach((row) => {
      if (isVsp) {
        proFanspeedData.push([moment(row.logtime).valueOf(), row.vspIn]);
      } else {
        basicFanspeedData.push([moment(row.logtime).valueOf(), row.fanspeed]);
      }
    });
    return [basicFanspeedData, proFanspeedData];
  };

  const getStatusIndex = (type, value) => {
    let i = 0;
    let elementValue;
    for (elementValue of criteriaGoodBad[type].range) {
      if (elementValue > value) {
        i--;
        break;
      }
      i++;
    }
    return criteriaGoodBad[type].quality[i];
  };

  if (currentPage === 6) {
    return <AwairMap />;
  }

  return (
    <PageWrap>
      <Logo>
        <img src="/logo.png" alt="CLAIRE" onClick={() => setCurrentPage(1)} />
      </Logo>

      {!isApp && avgScore && Math.floor(currentPage) === 1 && (
        <DailyReport
          date={date}
          isPaging={true}
          getStatusIndex={getStatusIndex}
          avgScore={avgScore}
          avgTemp={avgTemp}
          avgHumid={avgHumid}
          avgCo2={avgCo2}
          avgVoc={avgVoc}
          avgPm25={avgPm25}
        />
      )}
      {graphData && isVsp !== "READY" && isLoading.current !== "TRUE" && (
        <div id="charts">
          {currentPage === 2 && (
            <>
              <Desc>
                현재 종합공기질 상태는 {avgScore}점으로{" "}
                <span style={{ color: TC[getStatusIndex("awairScore", avgScore)] || "#999" }}>
                  {textAry[getStatusIndex("awairScore", avgScore)] || "미집계"}
                </span>{" "}
                상태입니다.
              </Desc>
              <ChartWrap>
                <ChartTitle text="종합공기질" isApp={isApp} />
                <InbodyCharts kind="awairScore" data={graphData.awairScore} isApp={isApp} />
              </ChartWrap>
            </>
          )}
          {currentPage === 3 && (
            <>
              <Desc>
                현재 이산화탄소 수치는 {avgCo2}ppm으로{" "}
                <span style={{ color: TC[getStatusIndex("co2", avgCo2)] || "#999" }}>{textAry[getStatusIndex("co2", avgCo2)] || "미집계"}</span> 상태 입니다.
              </Desc>
              <ChartWrap>
                <ChartTitle text="이산화탄소" isApp={isApp} />
                <InbodyCharts kind="CO2" data={graphData.CO2} isApp={isApp} />
              </ChartWrap>
            </>
          )}
          {currentPage === 4 && (
            <>
              <Desc>
                현재 휘발성유기화합물 수치는 {avgVoc}ppb로{" "}
                <span style={{ color: TC[getStatusIndex("voc", avgVoc)] || "#999" }}>{textAry[getStatusIndex("voc", avgVoc)] || "미집계"}</span> 상태 입니다.
              </Desc>
              <ChartWrap>
                <ChartTitle text="휘발성유기화합물" isApp={isApp} />
                <InbodyCharts kind="VOC" data={graphData.VOC} isApp={isApp} />
              </ChartWrap>
            </>
          )}
          {currentPage === 5 && (
            <ChartWrap>
              <ChartTitle text="팬 스피드" isApp={isApp} />
              <InbodyCharts kind="fanSpeed" data={graphData.fanspeed} isApp={isApp} isVsp={isVsp} />
            </ChartWrap>
          )}
        </div>
      )}
    </PageWrap>
  );
}

export default InbodyChartGroup;
