import React, { useContext, useEffect, useState } from "react";
import InbodyLayout from "../../Layouts/inbody/Layout";
import styled from "styled-components";
import SelfContext from "../../selfContext";
import InbodyChartGroup from "../../Containers/inbody/ChartGroup";
import useInterval from "../../hooks/useInterval";
import { INIT_INBODY_PAGE } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";

function InbodyDashboard() {
  const { self } = useContext(SelfContext);
  const [date, setDate] = useState(Date.now());
  const [currentPage, setCurrentPage] = useState(1); // 1 ~ 6
  const userId = self.user._id;

  const { data } = useQuery(INIT_INBODY_PAGE, {
    variables: { userId: userId },
    pollInterval: 1000 * 60 * 3,
  });

  //화면 전환
  useInterval(() => {
    if (Math.floor(currentPage) === 1) {
      // 1페이지의 경우 다른 페이지들보다 두배 더 노출
      setCurrentPage(currentPage + 0.5);
    } else if (currentPage === 6) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  }, 1000 * 10);

  if (!data) return <InbodyLayout></InbodyLayout>;

  return (
    <InbodyLayout>
      <InbodyChartGroup floors={data.user.floors} date={date} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </InbodyLayout>
  );
}

export default InbodyDashboard;
