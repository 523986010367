import gql from "graphql-tag";

export const ME = gql`
  query me {
    me {
      _id
      createdAt
      updatedAt
      id
      name
      phone
      email
      user {
        # null 반환됨. 오류 발생
        _id
      }
      status
    }
  }
`;

export const INIT_DASHBOARD_PAGE = gql`
  query initDashboardPage($userId: ID!, $from: Date!, $to: Date!) {
    user(userId: $userId) {
      _id
      name
      createdAt
      floors {
        _id
        name
        sections {
          _id
          name
          indoorAtmLogsByDate(from: $from, to: $to, sample: 200) {
            measurements {
              logtime
              temp
              humid
              co2
              co2_15
              co2_90
              voc
              voc_15
              voc_90
              pm25
              awairScore
            }
          }
        }
        ervs {
          _id
          name
          status
          ervCtrl {
            _id
            id
            name
            autoControlOn
          }
          ervLogsByDate(from: $from, to: $to) {
            isVsp
            actions {
              fanspeed
              logtime
              ventLevel
              vspIn
              vspOut
            }
          }
        }
      }
    }
  }
`;

export const INIT_MODES_COMPONENT = gql`
  query initModesComponent($userId: ID!) {
    user(userId: $userId) {
      _id
      name
      floors {
        _id
        name
        ervs {
          _id
          name
          status
          ervCtrl {
            _id
            id
            name
            autoControlOn
            action
          }
        }
      }
    }
  }
`;

export const INIT_AI_SETTING_PAGE = gql`
  query initAiSettingPage($userId: ID!) {
    user(userId: $userId) {
      _id
      profile
    }
  }
`;

export const INIT_INBODY_PAGE = gql`
  query initInbodyPage($userId: ID!) {
    user(userId: $userId) {
      _id
      name
      createdAt
      floors {
        _id
        name
        sections {
          _id
          name
          getTodayIndoorAtmLogs(sample: 200) {
            measurements {
              logtime
              temp
              humid
              co2
              co2_15
              co2_90
              voc
              voc_15
              voc_90
              pm25
              awairScore
            }
          }
        }
        ervs {
          _id
          name
          getTodayErvLogs {
            isVsp
            actions {
              fanspeed
              logtime
            }
          }
        }
      }
    }
  }
`;

export const INIT_APP_CHATBOT_PAGE = gql`
  query initAppChatbotPage($userId: ID!) {
    user(userId: $userId) {
      _id
      email
      name
    }
  }
`;
