import React, { useEffect, useState, useRef } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import Charts from "Components/Charts";
import moment from "moment";
import { SubTitle, AppSubTitle } from "../styles/common";

function ChartGroup({ floors, date, isApp = false }) {
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVsp, setIsVsp] = useState("READY"); // READY | TRUE | FALSE

  const yAxisIndexForHide = useRef();

  // date, floor 변화에 따라  챠트 그림
  useEffect(() => {
    (async () => {
      if (!date || !floors) return;

      let newGraphData = {
        CO2: [],
        VOC: [],
        PM25: [],
        awairScore: [],
      };

      let fanspeedDatas = [];

      const colorArr = ["#00C1C4", "#0BDBA4", "#0AD164", "#0BA1DB", "#0A65D1"];
      const fanspeedColors = ["#FFD215", "#CCA610", "#807030"];

      for (const floor of floors) {
        // 팬스피드 데이터 가공
        for (const erv of floor.ervs) {
          if (!erv.ervLogsByDate || !erv.ervLogsByDate.actions.length) continue;
          setIsVsp(erv.ervLogsByDate.isVsp ? "TRUE" : "FALSE");
          const [basic, pro] = formatFanspeedDataForGraph(erv.ervLogsByDate.isVsp, erv.ervLogsByDate.actions);

          if (erv.ervLogsByDate.isVsp === true) {
            fanspeedDatas.push({ name: `ventLevel[erv:${erv.name} / floor:${floor.name} ]`, data: pro.ventLevel, yAxis: 1 });
            fanspeedDatas.push({ name: `vspIn[erv:${erv.name} / floor:${floor.name} ]`, data: pro.vspIn, yAxis: 1 });
            fanspeedDatas.push({ name: `vspOut[erv:${erv.name} / floor:${floor.name} ]`, data: pro.vspOut, yAxis: 1 });
            yAxisIndexForHide.current = 0;
          } else {
            fanspeedDatas.push({ name: `fanspeed[erv:${erv.name}]`, data: basic });
            yAxisIndexForHide.current = 1;
          }

          // basic.length > 0 ? fanspeedDatas.push({ name: `fanspeed[ erv:${erv.name} / floor:${floor.name} ]`, data: basic }) : (yAxisIndexForHide.current = 0);
          // pro.length > 0 ? fanspeedDatas.push({ name: `VSP[ erv:${erv.name} / floor:${floor.name} ]`, data: pro }) : (yAxisIndexForHide.current = 1);
        }

        // innerAQI 데이터 가공
        floor.sections.forEach((section, index) => {
          if (!section.indoorAtmLogsByDate || !section.indoorAtmLogsByDate.measurements) return true;

          let thisName;
          let co2 = [];
          let voc = [];
          let awairScores = [];

          for (const item of section.indoorAtmLogsByDate.measurements) {
            const ts = moment(item.logtime).valueOf();
            item.co2 && co2.push([ts, item.co2]);
            item.voc && voc.push([ts, item.voc]);
            item.awairScore && awairScores.push([ts, item.awairScore]);
          }

          thisName = section.name;
          newGraphData.CO2.push({ type: "line", name: thisName, data: co2, color: colorArr[index] });
          newGraphData.VOC.push({ type: "line", name: thisName, data: voc, color: colorArr[index] });
          newGraphData.awairScore.push({ name: thisName, data: awairScores, color: colorArr[index] });
        });

        fanspeedDatas.forEach((fanspeedData, index) => {
          if (!fanspeedData.data) return true;

          let chartType = "area";
          if (isApp) chartType = "line";
          // newGraphData.CO2.push({ type: "area", name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
          // newGraphData.VOC.push({ type: "area", name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
          // newGraphData.awairScore.push({ type: "area", name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
          newGraphData.CO2.push({ type: chartType, name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
          newGraphData.VOC.push({ type: chartType, name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
          newGraphData.awairScore.push({ type: chartType, name: "환기 강도", data: fanspeedData.data, color: fanspeedColors[index], yAxis: 1 });
        });

        setGraphData(newGraphData);
        setIsLoading(false);
      }
    })();
  }, [date, floors]);

  const formatFanspeedDataForGraph = (isVsp, rawData) => {
    const proFanspeedData = { ventLevel: [], vspIn: [], vspOut: [] };
    const basicFanspeedData = [];
    for (let i = 0; i < rawData.length; i++) {
      if (isVsp) {
        proFanspeedData.vspIn.push([moment(rawData[i].logtime).valueOf(), rawData[i].vspIn]);
        proFanspeedData.vspOut.push([moment(rawData[i].logtime).valueOf(), rawData[i].vspOut]);
        proFanspeedData.ventLevel.push([moment(rawData[i].logtime).valueOf(), rawData[i].ventLevel]);
      } else {
        if (i > 0) basicFanspeedData.push([moment(rawData[i].logtime).subtract(1, "seconds").valueOf(), rawData[i - 1].fanspeed]);
        basicFanspeedData.push([moment(rawData[i].logtime).valueOf(), rawData[i].fanspeed]);
      }
    }
    return [basicFanspeedData, proFanspeedData];
  };

  return (
    <>
      <StyledSpin spinning={isLoading} size="large" delay={500} />
      {graphData && !isLoading && (
        <ChartsWrap id="charts">
          <ChartWrap>
            <ChartTitle text="종합 점수" isApp={isApp} />
            <Charts kind="awairScore" data={graphData.awairScore} isApp={isApp} isVsp={isVsp} yAxisIndexForHide={yAxisIndexForHide.current} />
          </ChartWrap>
          <ChartWrap>
            <ChartTitle text="이산화탄소 (CO₂)" isApp={isApp} />
            <Charts kind="CO2" data={graphData.CO2} isApp={isApp} isVsp={isVsp} yAxisIndexForHide={yAxisIndexForHide.current} />
          </ChartWrap>
          <ChartWrap>
            <ChartTitle text="휘발성 유기화합물 (VOCs)" isApp={isApp} />
            <Charts kind="VOC" data={graphData.VOC} isApp={isApp} isVsp={isVsp} yAxisIndexForHide={yAxisIndexForHide.current} />
          </ChartWrap>
        </ChartsWrap>
      )}
    </>
  );
}

export default ChartGroup;

const ChartsWrap = styled.div`
  position: relative;
`;

const ChartWrap = styled.div`
  position: relative;
`;

const StyledSpin = styled(Spin)`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ChartTitle = ({ text, isApp }) => {
  if (isApp) {
    return <AppSubTitle>{text}</AppSubTitle>;
  } else {
    return <SubTitle>{text}</SubTitle>;
  }
};
