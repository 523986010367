import React, { useEffect, useState, useCallback } from "react";
import AppLayout from "../Layouts/AppLayout";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import ChartGroup from "../Containers/ChartGroup";
import TimeLine from "../Containers/TimeLine";
import styled from "styled-components";
import moment from "moment";
import { AppSubTitle } from "../styles/common";
import { INIT_DASHBOARD_PAGE } from "../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";
import useInterval from "../hooks/useInterval";

function AppDashboard() {
  const { token, userId } = useParams();
  const [date, setDate] = useState(Date.now());

  const [initDashboardPage, { loading, error, data, refetch }] = useLazyQuery(INIT_DASHBOARD_PAGE);

  useEffect(() => {
    localStorage.setItem("currentUser", token);
    const startTime = moment(date).startOf("day");
    const endTime = moment(date).endOf("day");
    initDashboardPage({ variables: { userId: userId, from: startTime.valueOf(), to: endTime.valueOf() } });
  }, [date]);

  //  3분 간격으로 챠트 갱신
  useInterval(async () => {
    if (moment(date).isSame(moment(), "day")) {
      refetch();
    }
  }, 1000 * 60 * 3);

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const disabledDate = (current) => {
    return current < moment(data.user.createdAt).startOf("dat") || current > moment().endOf("day"); // user 생성 기준
  };

  if (!data) return <AppLayout></AppLayout>;

  return (
    <AppLayout>
      {/* {erv && <TimeLine userId={userId} erv={erv} />} */}
      <AppSubTitle style={{ marginBottom: "20px" }}>날짜별 공기 상태</AppSubTitle>
      <StyledDatePicker onChange={handleChangeDate} defaultValue={moment(date)} format={"YYYY-MM-DD"} disabledDate={disabledDate} inputReadOnly={true} />
      <ChartGroup floors={data.user.floors} date={date} isApp={true} />
    </AppLayout>
  );
}

export default AppDashboard;

const StyledDatePicker = styled(DatePicker)`
  display: block;
  width: 200px;
  margin-bottom: 40px;
  margin-left: 28px;
`;
