import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout";
import { Form, Input, Button, message } from "antd";
import { MailOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Wrapper, formLayout, formTailLayout } from "../../styles/common";
import { FIND_PASSWORD } from "../../graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

function FindPassword() {
  const [findPassword] = useMutation(FIND_PASSWORD, {
    onCompleted(data) {
      message.success("임시 비밀번호가 생성되었습니다. 메일을 확인해 주세요.");
    },
    onError(error) {
      console.error(error);
      message.error("실패하였습니다.");
    },
  });

  const onFinish = async (values) => {
    await findPassword({ variables: { email: values.email } });
  };

  return (
    <DefaultLayout>
      <Wrapper>
        {" "}
        <h2>
          <QuestionCircleOutlined /> 비밀번호 찾기
        </h2>
        <Form {...formLayout} onFinish={onFinish}>
          <Form.Item
            label="이메일"
            name="email"
            rules={[
              {
                required: true,
                message: "가입한 이메일 주소를 입력해 주세요!",
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" className="login-form-button">
              비밀번호 찾기
            </Button>
            <Link to={`/login`} style={{ marginLeft: "20px" }}>
              로그인
            </Link>
          </Form.Item>
        </Form>
      </Wrapper>
    </DefaultLayout>
  );
}

export default FindPassword;
