import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import styled from "styled-components";
import { INIT_APP_CHATBOT_PAGE } from "../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

const StyledSpin = styled(Spin)`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function AppChatbot() {
  const { token, userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [initAppChatBotPage] = useLazyQuery(INIT_APP_CHATBOT_PAGE, {
    onCompleted(data) {
      const script = document.createElement("script");
      script.src = "https://sdk.alli.ai/latest/alli.js";
      script.async = true;
      script.onload = () => {
        window.Alli.initialize({
          apiKey: process.env.REACT_APP_ALLI_CHATBOT_KEY,
          debug: false,
          backButton: false,
          providerLink: true,
          popupMode: true,
          launcher: false,
        });
        window.Alli.setUser({
          id: userId,
          email: data.user.email,
        });
        window.Alli.event({
          placement: "APP",
          variables: [
            { name: "userId", value: userId },
            { name: "token", value: token },
            { name: "email", value: data.user.email },
            { name: "name", value: data.user.name },
          ],
        });
      };
      document.body.appendChild(script);
      setIsLoading(true);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    localStorage.setItem("currentUser", token);
    initAppChatBotPage({ variables: { userId: userId } });
  }, []);

  return (
    <div>
      <StyledSpin spinning={isLoading} size="large" delay={500} />
    </div>
  );
}

export default AppChatbot;
