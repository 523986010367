import gql from "graphql-tag";

export const SIGNIN = gql`
  mutation signin($id: String!, $password: String!) {
    signin(id: $id, password: $password) {
      accessToken
    }
  }
`;

export const FIND_PASSWORD = gql`
  mutation findPassword($email: String!) {
    findPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($accountId: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(accountId: $accountId, oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const MODIFY_ERVCTRL = gql`
  mutation modifyErvCtrl($ervCtrlId: ID!, $data: ErvCtrlInput!) {
    updateErvCtrl(ervCtrlId: $ervCtrlId, data: $data) {
      autoControlOn
      action
    }
  }
`;

export const MANUAL_MODE = gql`
  mutation manualMode($ervCtrlId: ID!, $ventLevel: Int!, $controlOn: Boolean!) {
    manualControlErvCtrl(ervCtrlId: $ervCtrlId, ventLevel: $ventLevel, controlOn: $controlOn) {
      action
    }
  }
`;

export const MODIFY_USER = gql`
  mutation modifyUser($userId: ID!, $data: UserInput!) {
    updateUser(userId: $userId, data: $data) {
      mailList
    }
  }
`;
