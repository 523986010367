const fanspeedLabels = ["중지", "약", "중", "강"];
export default {
  common: {
    time: {
      timezoneOffset: 9,
      useUTC: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          let moment = require("moment");
          return moment(this.value).format("HH:mm");
        },
      },
    },
    chart: {
      type: "spline",
      zoomType: "x",
    },
    plotOptions: {
      series: {
        boostThreshold: 500,
      },
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(255, 210, 21, 0.8)"],
            [1, "rgba(255, 210, 21, 0)"],
          ],
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: "50",
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
    },
  },

  CO2: {
    // title: {
    //   text: "CO2",
    //   align: "left",
    //   style: {
    //     "font-weight": "bold",
    //     fontSize: "18px",
    //     color: "#5B7784",
    //   },
    // },
    title: { text: "", style: { display: "none" } },
    yAxis: [
      {
        title: false,
        labels: {
          style: {
            color: "#00C3C5",
          },
        },
      },
      {
        title: false,
        labels: {
          formatter: function () {
            return fanspeedLabels[this.value];
          },
          style: {
            color: "#ffd215",
          },
        },
        max: 3,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
        tickPositioner: function (min, max) {
          var step = 1,
            ret = [];
          for (var i = min; i <= max; i += step) {
            ret.push(i);
          }
          return ret;
        },
      },
      {
        title: false,
        labels: {
          style: {
            color: "#ffd215",
          },
        },
        max: 100,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
      },
    ],
  },
  VOC: {
    title: { text: "", style: { display: "none" } },
    yAxis: [
      {
        title: false,
        labels: {
          style: {
            color: "#00C3C5",
          },
        },
        min: 0,
      },
      {
        title: false,
        labels: {
          formatter: function () {
            return fanspeedLabels[this.value];
          },
          style: {
            color: "#ffd215",
          },
        },
        max: 3,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
        tickPositioner: function (min, max) {
          var step = 1,
            ret = [];
          for (var i = min; i <= max; i += step) {
            ret.push(i);
          }
          return ret;
        },
      },
      {
        title: false,
        labels: {
          style: {
            color: "#ffd215",
          },
        },
        max: 100,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
      },
    ],
  },
  awairScore: {
    title: { text: "", style: { display: "none" } },
    yAxis: [
      {
        title: false,
        labels: {
          style: {
            color: "#00C3C5",
          },
        },
      },

      {
        title: false,
        labels: {
          formatter: function () {
            return fanspeedLabels[this.value];
          },
          style: {
            color: "#ffd215",
          },
        },
        max: 3,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
        tickPositioner: function (min, max) {
          var step = 1,
            ret = [];
          for (var i = min; i <= max; i += step) {
            ret.push(i);
          }
          return ret;
        },
      },
      {
        title: false,
        labels: {
          style: {
            color: "#ffd215",
          },
        },
        max: 100,
        min: 0,
        opposite: true,
        gridLineColor: "transparent",
      },
    ],
  },
};
