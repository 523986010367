import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { TC } from "../styles/color";
import { Wrapper } from "../styles/common";
import moment from "moment";

const textAry = ["매우 나쁨", "나쁨", "보통", "좋음", "매우 좋음"];

const Title = styled.div`
  font-weight: 400;
  font-size: 40px;
  color: #5b7784;
  margin-bottom: 20px;
  z-index: 1;

  span {
    margin-left: 6px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
  }
`;

const Item = styled.div``;

const ItemTop = styled.div`
  margin-left: 25px;
  margin-top: 60px;
  margin-bottom: 240px;
`;

const Label = styled.div`
  font-size: ${(props) => (props.size === "big" ? "28px" : "24px")};
  font-weight: ${(props) => (props.size === "big" ? 300 : 200)};
  margin-bottom: 6px;
`;

const Status = styled.div`
  position: relative;
  i {
    display: inline-block;
    width: ${(props) => (props.size === "big" ? "20px" : "10px")};
    height: ${(props) => (props.size === "big" ? "20px" : "10px")};
    background-color: ${(props) => props.color};
    border-radius: 100%;
    margin-top: 10px;
  }
  span {
    position: absolute;
    top: 4px;
    font-size: ${(props) => (props.size === "big" ? "22px" : "16px")};
    margin-left: ${(props) => (props.size === "big" ? "16px" : "6px")};
    color: ${(props) => props.color};
  }
`;

const Value = styled.div`
  font-size: ${(props) => (props.size === "big" ? "95px" : "30px")};
  font-weight: ${(props) => (props.size === "big" ? 100 : 200)};
  line-height: ${(props) => props.size === "big" && 1.2};

  span {
    margin-left: 3px;
    font-size: 10px;
  }
`;

const Puffin = styled.div`
  position: absolute;
  top: 138px;
  right: 140px;
  text-align: center;
  h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 0;
  }
  p {
    font-size: 28px;
    color: #1caae5;
    margin-bottom: 0px;
  }
  img {
    width: 360px;
  }
`;

function Summary({ getStatusIndex, avgScore, avgCo2, avgVoc, avgPm25, avgTemp, avgHumid, date }) {
  return (
    <Wrapper style={{ backgroundColor: "white", border: "none", padding: "20px 20px", minHeight: "900px" }}>
      <Puffin>
        <h2>지능형 환기 시스템</h2>
        <p>ACTIVATED</p>
        <img src="/chart.png" alt="chart" />
      </Puffin>

      <Title>
        Daily Report <span>{moment(date).format("YYYY-MM-DD")}</span>
      </Title>

      <ItemTop>
        <Label size="big">종합 공기질</Label>
        <Value size="big">{avgScore}</Value>
        <Status color={TC[getStatusIndex("awairScore", avgScore)] || "#999"} size="big">
          <i />
          <span>{textAry[getStatusIndex("awairScore", avgScore)] || "미집계"}</span>
        </Status>
      </ItemTop>

      <Row style={{ marginLeft: 25 }}>
        <Col sm={8} xs={12} style={{ marginBottom: "30px" }}>
          <Item>
            <Label>이산화탄소</Label>
            <Status color={TC[getStatusIndex("co2", avgCo2)] || "#999"}>
              <i />
              <span>{textAry[getStatusIndex("co2", avgCo2)] || "미집계"}</span>
            </Status>
            <Value>
              {avgCo2}
              <span>ppm</span>
            </Value>
          </Item>
        </Col>
        <Col sm={8} xs={12} style={{ marginBottom: "30px" }}>
          <Item>
            <Label>휘발성유기화합물</Label>
            <Status color={TC[getStatusIndex("voc", avgVoc)] || "#999"}>
              <i />
              <span>{textAry[getStatusIndex("voc", avgVoc)] || "미집계"}</span>
            </Status>
            <Value>
              {avgVoc}
              <span>ppb</span>
            </Value>
          </Item>
        </Col>
        <Col sm={8} xs={12} style={{ marginBottom: "30px" }}>
          <Item>
            <Label>초미세먼지</Label>
            <Status color={TC[getStatusIndex("pm25", avgPm25)] || "#999"}>
              <i />
              <span>{textAry[getStatusIndex("pm25", avgPm25)] || "미집계"}</span>
            </Status>
            <Value>
              {avgPm25}
              <span>µg/m³</span>
            </Value>
          </Item>
        </Col>
        <Col sm={8} xs={12} style={{ marginBottom: "30px" }}>
          <Item>
            <Label>온도</Label>
            <Status color={TC[getStatusIndex("temp", avgTemp)] || "#999"}>
              <i />
              <span>{textAry[getStatusIndex("temp", avgTemp)] || "미집계"}</span>
            </Status>
            <Value>
              {avgTemp}
              <span>°C</span>
            </Value>
          </Item>
        </Col>
        <Col sm={8} xs={12} style={{ marginBottom: "30px" }}>
          <Item>
            <Label>습도</Label>
            <Status color={TC[getStatusIndex("humid", avgHumid)] || "#999"}>
              <i />
              <span>{textAry[getStatusIndex("humid", avgHumid)] || "미집계"}</span>
            </Status>
            <Value>
              {avgHumid}
              <span>%</span>
            </Value>
          </Item>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default Summary;
