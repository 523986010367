import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 20px;
  background: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  min-height: 130px;

  h2 {
    color: #5b7784;
  }

  &.app-wrapper {
    background: #fff;
  }
`;

export const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const formTailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  color: #5b7784;
  position: absolute;
  top: 16px;
  left: 31px;
  z-index: 1;
`;

export const AppSubTitle = styled.h2`
  font-weight: bold;
  font-size: 14px;
  color: #9f9f9f;
  margin-left: 28px;
  margin-bottom: 0;
`;
