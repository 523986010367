import React, { useState, useContext } from "react";
import DefaultLayout from "../Layouts/DefaultLayout";
import SelfContext from "../selfContext";
import { Button, message, TimePicker, Slider } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Wrapper } from "../styles/common";
import { INIT_AI_SETTING_PAGE } from "../graphql/query";
import { MODIFY_USER } from "../graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { cloneDeep } from "lodash";
import styled from "styled-components";

const sensitivityLabel = { 0: "매우낮음", 1: "낮음", 2: "보통", 3: "높음", 4: "매우높음" };

const sensitivityValue = (value) => {
  let sliderNum;
  if (value === "very low") {
    sliderNum = 0;
  } else if (value === "low") {
    sliderNum = 1;
  } else if (value === "mid") {
    sliderNum = 2;
  } else if (value === "high") {
    sliderNum = 3;
  } else if (value === "very high") {
    sliderNum = 4;
  }
  return sliderNum;
};

function AiSetting() {
  const { self } = useContext(SelfContext);
  const userId = self.user._id;

  const [sleepTimeFrom, setSleepTimeFrom] = useState();
  const [sleepTimeTo, setSleepTimeTo] = useState();
  const [noiseSensitivity, setNoiseSensitivity] = useState(0);

  const { data, loading } = useQuery(INIT_AI_SETTING_PAGE, {
    variables: { userId: userId },
    onCompleted(data) {
      if (data.user.profile?.noiseSensitivity) setNoiseSensitivity(sensitivityValue(data.user.profile.noiseSensitivity));
      if (data.user.profile?.sleepTime?.from) setSleepTimeFrom(data.user.profile.sleepTime.from);
      if (data.user.profile?.sleepTime?.to) setSleepTimeTo(data.user.profile.sleepTime.to);
    },
    onError(error) {
      console.error(error);
    },
  });

  const [modifyUser] = useMutation(MODIFY_USER, {
    onCompleted(data) {
      message.success("AI 자율 환기 설정이 변경되었습니다.");
    },
    onError(error) {
      message.error("AI 자율 환기 설정 변경이 실패하였습니다.");
    },
  });

  const handleChangeSleepTimeFrom = (time, timeString) => {
    setSleepTimeFrom(Number(timeString));
  };

  const handleChangeSleepTimeTo = (time, timeString) => {
    setSleepTimeTo(Number(timeString));
  };

  const handleChangeSensitivity = (value) => {
    setNoiseSensitivity(value);
  };

  const handleSubmit = () => {
    console.log("click!");
    console.log(sleepTimeFrom, sleepTimeTo, noiseSensitivity);
    const sensiAry = ["very low", "low", "mid", "high", "very high"];

    const newProfile = cloneDeep(data.user.profile);
    if (noiseSensitivity) newProfile.noiseSensitivity = sensiAry[noiseSensitivity];
    if (!isNaN(sleepTimeFrom) && !isNaN(sleepTimeTo)) {
      newProfile.sleepTime.from = sleepTimeFrom;
      newProfile.sleepTime.to = sleepTimeTo;
    }
    modifyUser({ variables: { userId: userId, data: { profile: newProfile } } });
  };

  return (
    <DefaultLayout>
      <Wrapper>
        <h2>
          <SettingOutlined /> AI 자율 환기 설정
        </h2>
        <SettingItem>
          <h4>수면 시간대</h4>
          <TimePicker
            use12Hours
            onChange={handleChangeSleepTimeFrom}
            value={!isNaN(sleepTimeFrom) && moment(String(sleepTimeFrom), "h")}
            format="HH"
            placeholder="취침 시간"
          />{" "}
          ~{" "}
          <TimePicker
            use12Hours
            onChange={handleChangeSleepTimeTo}
            value={!isNaN(sleepTimeTo) && moment(String(sleepTimeTo), "h")}
            format="HH"
            placeholder="기상 시간"
          />
        </SettingItem>
        <SettingItem>
          <h4>소음 민감도</h4>
          <Slider
            marks={sensitivityLabel}
            step={1}
            value={noiseSensitivity}
            min={0}
            max={4}
            included={false}
            onChange={(e) => handleChangeSensitivity(e)}
            style={{ margin: "0 20px" }}
          />
        </SettingItem>
        <Button type="primary" block onClick={handleSubmit} style={{ marginTop: 20 }}>
          설정
        </Button>
      </Wrapper>
    </DefaultLayout>
  );
}

export default AiSetting;

const SettingItem = styled.div`
  margin: 40px 20px;
`;
