import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

const StyledLayout = styled(Layout)`
  overflow: hidden;
`;

const Container = styled.div`
  background: #fff;
  padding: 38px 18px;
  width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

function AppLayout({ children }) {
  return (
    <StyledLayout>
      <Container>
        <Layout.Content>{children}</Layout.Content>
      </Container>
    </StyledLayout>
  );
}
export default AppLayout;
