import React, { useEffect, useState } from "react";
import { Switch, message, Slider, Select } from "antd";
import styled from "styled-components";

import { INIT_MODES_COMPONENT } from "../graphql/query";
import { MODIFY_ERVCTRL, MANUAL_MODE } from "../graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { cloneDeep } from "lodash";

function Modes({ userId }) {
  const [currentErvId, setCurrentErvId] = useState();
  const [ervsOpt, setErvsOpt] = useState([]);
  const [isAutoOn, setIsAutoOn] = useState(true);
  const [sliderFanspeedVal, setSliderFanspeedVal] = useState(0);
  const [isAIOnOffLoading, setIsAIOnOffLoading] = useState(false);
  const [isChangingSlider, setIsChangingSlider] = useState(false);

  const fanspeedLabel = {
    0: "정지",
    1: "약",
    2: "중",
    3: "강",
  };

  const IndexAndLabel = {
    0: [0, "정지"],
    40: [1, "약"],
    60: [2, "중"],
    80: [3, "강"],
  };

  const { data, loading } = useQuery(INIT_MODES_COMPONENT, {
    variables: { userId: userId },
    onCompleted(data) {
      const ervOptArr = [];
      data.user.floors.map((floor) => {
        floor.ervs.map((erv, index) => {
          if (index === 0) {
            setCurrentErvId(erv._id);
            setIsAutoOn(erv.ervCtrl.autoControlOn);
          }
          ervOptArr.push({ label: erv.name, key: erv._id, value: erv._id });
        });
      });
      setErvsOpt(ervOptArr);
    },
  });

  useEffect(() => {
    try {
      if (!currentErvId) return;
      data.user.floors.map((floor) => {
        floor.ervs.map((erv) => {
          if (erv._id === currentErvId) {
            setIsAutoOn(erv.ervCtrl.autoControlOn);
            erv.ervCtrl.action?.manualControl?.ventLevel && setSliderFanspeedVal(IndexAndLabel[erv.ervCtrl.action.manualControl.ventLevel][0]);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [currentErvId]);

  const [modifyErvCtrl] = useMutation(MODIFY_ERVCTRL, {
    onCompleted(data) {
      setIsAutoOn(data.updateErvCtrl.autoControlOn);
      if (data.updateErvCtrl.autoControlOn) {
        message.success("자동 환기 시스템을 실행합니다.");
      } else {
        message.success("자동 환기 시스템을 종료하였습니다.");
      }
      setIsAIOnOffLoading(false);
    },
    onError(error) {
      console.error(error);
      message.error("자동 환기 시스템 전환이 실패하였습니다.");
      setIsAIOnOffLoading(false);
    },
    update(cache, { data }) {
      const existingData = cache.readQuery({ query: INIT_MODES_COMPONENT, variables: { userId: userId } });
      const copiedUser = cloneDeep(existingData.user);
      copiedUser.floors.map((floor) => {
        floor.ervs.map((erv) => {
          if (erv._id === currentErvId) {
            erv.ervCtrl.autoControlOn = data.updateErvCtrl.autoControlOn;
          }
        });
      });
      cache.writeQuery({
        query: INIT_MODES_COMPONENT,
        data: { user: copiedUser },
      });
    },
    // refetchQueries: [{ query: INIT_MODES_COMPONENT, variables: { userId: userId } }],
  });

  const handleChangeAIOnOff = (checked) => {
    setIsAIOnOffLoading(true);

    if (checked) {
      // AI 자율 환기
      modifyErvCtrl({ variables: { ervCtrlId: getCurrentErvCtrlId(), data: { autoControlOn: true } } });
    } else {
      // 수동
      modifyErvCtrl({ variables: { ervCtrlId: getCurrentErvCtrlId(), data: { autoControlOn: false } } });
    }
  };

  const [manualMode] = useMutation(MANUAL_MODE, {
    onCompleted(data) {
      setSliderFanspeedVal(IndexAndLabel[data.manualControlErvCtrl.action.manualControl.ventLevel][0]);
      message.success(`환기 강도가 변경되었습니다. ( ${IndexAndLabel[data.manualControlErvCtrl.action.manualControl.ventLevel][1]} )`);
      setIsChangingSlider(false);
    },
    onError(error) {
      console.error(error);
      setIsChangingSlider(false);
    },
    update(cache, { data }) {
      const existingData = cache.readQuery({ query: INIT_MODES_COMPONENT, variables: { userId: userId } });
      const copiedUser = cloneDeep(existingData.user);
      copiedUser.floors.map((floor) => {
        floor.ervs.map((erv) => {
          if (erv._id === currentErvId) {
            erv.ervCtrl.action = data.manualControlErvCtrl.action;
          }
        });
      });
      cache.writeQuery({
        query: INIT_MODES_COMPONENT,
        data: { user: copiedUser },
      });
    },
  });

  const handleChangeFanspeedSlider = (fanspeed) => {
    if (isChangingSlider) return;
    setIsChangingSlider(true);
    const ventLevelAry = [0, 40, 60, 80]; // 약(vsp 40) 중(60) 강(80)
    manualMode({ variables: { ervCtrlId: getCurrentErvCtrlId(), ventLevel: ventLevelAry[fanspeed], controlOn: true } });
  };

  const getCurrentErvCtrlId = () => {
    let currentErvCtrlId;
    data.user.floors.map((floor) => {
      floor.ervs.map((erv) => {
        if (erv._id === currentErvId) currentErvCtrlId = erv.ervCtrl._id;
      });
    });
    return currentErvCtrlId;
  };

  if (loading || !currentErvId) return null;

  return (
    <>
      <Panels>
        <Select defaultValue={currentErvId} options={ervsOpt} style={{ width: "100%", marginBottom: 8 }} onChange={(value) => setCurrentErvId(value)} />
        자동 환기 시스템{" "}
        <Switch checkedChildren="on" unCheckedChildren="off" onChange={(e) => handleChangeAIOnOff(e)} checked={isAutoOn} loading={isAIOnOffLoading} />
      </Panels>
      {!isAutoOn && (
        <Slider marks={fanspeedLabel} step={1} value={sliderFanspeedVal} min={0} max={3} included={false} onChange={(e) => handleChangeFanspeedSlider(e)} />
      )}
    </>
  );
}
export default Modes;

const Panels = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;
