import React, { useContext } from "react";
import { Menu, Dropdown, Button } from "antd";
import { MenuOutlined, LogoutOutlined, ToolOutlined, LineChartOutlined, SettingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import SelfContext from "../selfContext";

const Logo = styled.img`
  width: 220px;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  margin-top: 40px;
`;

const HeaderMenu = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

function Header() {
  const history = useHistory();
  const location = useLocation();
  const { self } = useContext(SelfContext);

  const logout = () => {
    // signout();
    localStorage.removeItem("currentUser");
    history.push("/login");
  };

  const menu = (
    <Menu>
      {location.pathname !== "/" && (
        <Menu.Item>
          <Link to={`/`} rel="noopener noreferrer">
            <LineChartOutlined /> 대시보드
          </Link>
        </Menu.Item>
      )}

      <Menu.Item>
        <Link to={`/setting`} rel="noopener noreferrer">
          <SettingOutlined /> AI 자율 환기 설정
        </Link>
      </Menu.Item>

      <Menu.Item>
        <Link to={`/modifypassword`} rel="noopener noreferrer">
          <ToolOutlined /> 비밀번호 변경
        </Link>
      </Menu.Item>

      <Menu.Item>
        <a rel="noopener noreferrer" href="/" onClick={logout}>
          <LogoutOutlined /> 로그아웃
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Link to={"/"}>
        <Logo src="/logo.png" alt="CLAIRE" />
      </Link>
      <Title>HOME DASHBOARD</Title>
      {self && (
        <HeaderMenu>
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button type="link">
              <MenuOutlined style={{ fontSize: "24px" }} />
            </Button>
          </Dropdown>
        </HeaderMenu>
      )}
    </>
  );
}

export default Header;
