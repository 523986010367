import React, { useContext } from "react";
import DefaultLayout from "../../Layouts/DefaultLayout";
import SelfContext from "../../selfContext";
import { Form, Input, Button, message } from "antd";
import { LockOutlined, ToolOutlined } from "@ant-design/icons";
import { Wrapper, formLayout, formTailLayout } from "../../styles/common";
import { useHistory } from "react-router-dom";
import { CHANGE_PASSWORD } from "../../graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

function ModifyPassword() {
  const history = useHistory();
  const { self } = useContext(SelfContext);
  const userId = self.user._id;

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted(data) {
      history.push("/");
      message.success("비밀번호가 수정되었습니다.");
    },
    onError(error) {
      console.error(error);
      message.error("비밀번호 수정이 실패하였습니다.");
    },
  });

  const onFinish = async (values) => {
    await changePassword({ variables: { accountId: userId, oldPassword: values.oldPassword, newPassword: values.password } });
  };

  return (
    <DefaultLayout>
      <Wrapper>
        <h2>
          <ToolOutlined /> 비밀번호 수정
        </h2>
        <Form {...formLayout} onFinish={onFinish}>
          <Form.Item label="현재 비밀번호" name="oldPassword" rules={[{ required: true, message: "현재 비밀번호를 입력해주세요!" }]}>
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Current Password" />
          </Form.Item>

          <Form.Item
            label="새 비밀번호"
            name="password"
            rules={[
              { required: true, message: "새 비밀번호를 입력해주세요!" },
              { min: 5, message: "비밀번호는 다섯자리 이상으로 지정해 주세요." },
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item
            label="새 비밀번호 확인"
            name="passwordCheck"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "새 비밀번호 확인을 입력해주세요!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("비밀번호가 일치하지 않습니다!");
                },
              }),
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password Check" />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" className="login-form-button">
              비밀번호 변경
            </Button>
          </Form.Item>
        </Form>
      </Wrapper>
    </DefaultLayout>
  );
}

export default ModifyPassword;
